<template>
  <div id="main">
    <div class="main-centent">
        <iframe src="https://ylsp.tv/" width="100%" height="100%" frameborder="0"></iframe>
    </div>
    <div class="main-gg" v-if="view">
      <div class="close" @click="view = false">
        <a style="color: aliceblue;" href="https://www.kuilld.com" target="_blank" rel="noopener noreferrer"><i class="el-icon-circle-close"></i></a>
        
      </div>
      <img class="main-gg-img" src="../assets/img/favicon.png" alt="">
      <h3 class="main-gg-title">Kuilld.com</h3>
      <div class="main-gg-dis">
        麻豆传媒、instagram、tiktok、youtube、google、chatgtp等国际网络使用教程，
        第一时间获取世界最新消息，打破国内信息差
      </div>
        <div class="button">
          <a style="color: aliceblue;" href="https://www.kuilld.com" target="_blank" rel="noopener noreferrer">打开世界</a>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      view: true,
    };
  },

  components: {
  },

  methods: {

  }
}
</script>

<style scoped>
#main {
  width: 100%;
  height: 100%;
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  background-color: rgba(0,0,0,0.88);
  background-image: url('../assets/img/dongtu.gif');
  background-repeat: no-repeat; /* 防止背景图片重复 */
  background-position: center center; /* 水平垂直居中 */
  background-size: 220px;
}

.main-centent{
  width: 100%;
  height: 100%;
}

.main-gg{
  position: fixed;
  z-index: 100000;
  background-color: rgba(0,0,0,0.5);
  bottom: 10%;
  right: 20px;
  width: 200px;
  height: 310px;
  border-radius: 10px;
  border-top-right-radius: 0px;
  backdrop-filter: blur(10px); 
  display: flex;
  flex-direction: column;
  align-items: center;
}

.close{
  position: absolute;
  top: 0px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 28px;
  left: 0px;
  cursor: pointer;
}

.main-gg-img{
  width: 40%;
  margin-top: 20px;
}

.main-gg-title{
  color: rgba(255,255,255,0.7);
  font-size: 20px;
  margin-top: 10px
}
.main-gg-dis{
  color: rgba(255,255,255,0.8);
  font-size: 13px;
  margin-top: 10px;
  line-height: 20px;
  padding: 10px;
}

.button{
    padding: 10px 20px;
    width: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 99px;
    font-size: 18px;
    color: rgba(255, 255, 255, 0.8);
    background-image: linear-gradient(25deg, #2600fc, #ff00ea);
}


</style>